<script lang="ts" setup>
definePageMeta({
    validate: async (route) => {
        return typeof route.params.slug === 'string'
    },
})

const { POST_DETAILS } = useContentService()

const { params } = useRoute()

const { data: epData } = await useAsyncData('post-details', (ctx) => {
    return POST_DETAILS(params.slug as string)
})

const route = useRoute()

const config = useRuntimeConfig()

const plainTitle = epData.value?.data.title.replace(/\.$/g, '')

const seoData = reactive({
    title: `${plainTitle} | Colección Arte Milenio`,
    description: `Descubre la ${
        epData.value?.data.type === 'collection' || epData.value?.data.type === 'special_project'
            ? 'colección'
            : 'exhibición'
    } ${plainTitle} en Colección Arte Milenio y todas las obras y artistas.`,
})

useHead({
    title: seoData.title,
    meta: [
        { property: 'title', content: seoData.title },
        { name: 'description', content: seoData.description },
        { property: 'og:title', content: seoData.title },
        { property: 'og:url', content: config.public.HOST + route.path },
        { property: 'og:description', content: seoData.description },
        { property: 'og:image', content: epData.value?.data.cover || '' },
        { name: 'twitter:title', content: seoData.title },
        { name: 'twitter:url', content: config.public.HOST + route.path },
        { name: 'twitter:image', content: epData.value?.data.cover || '' },
    ],
    link: [{ hid: 'canonical', rel: 'canonical', href: config.public.HOST + route.path }],
})

const breadcrumbs = [
    {
        title: 'Home',
        path: '/',
    },
    {
        title:
            route.name === 'series-slug'
                ? 'Series'
                : route.name === 'exhibiciones-slug'
                ? 'Exhibiciones'
                : 'Proyectos especiales',
        path:
            route.name === 'series-slug'
                ? '/series'
                : route.name === 'exhibiciones-slug'
                ? '/exhibiciones'
                : '/proyectos-especiales',
    },
    {
        title: epData.value?.data.title,
    },
]

const selectedArtistIndex = ref(0)

const showMoreArtworks = ref(false)
</script>

<template>
    <main v-if="epData" class="collection">
        <UtilsBreadcrumbs :layers="breadcrumbs" />
        <section class="collection__head">
            <img
                :src="epData.data.cover"
                alt="El arte por el vino"
                :onerror="`this.src=&quot;/no-image.jpg&quot;`"
            />
            <div class="head__info">
                <UtilsBookmark class="ml-auto text-xl border border-black" :post="epData.data" />
                <h1>{{ epData.data.title }}</h1>
                <p>{{ epData.data.year }}</p>
                <p v-if="(epData.data.content && epData.data.content.length > 0)" v-html="epData.data.content" />
                <NuxtLink to="#bloque-de-artistas">Artistas</NuxtLink>
            </div>
        </section>
        <section v-if="epData.data.artworks.data.length" class="collection__swiper">
            <div class="swiper__head">
                <h2>
                    Obras destacadas de la
                    {{
                        epData.data.type === 'collection' || epData.data.type === 'special_project'
                            ? 'serie'
                            : 'exhibición'
                    }}
                    ‘‘{{ epData.data.title }}’’
                </h2>
                <button
                    v-if="epData.data.artworks.data.length > 5"
                    type="button"
                    @click="showMoreArtworks = !showMoreArtworks"
                >
                    {{ showMoreArtworks ? 'Ver menos' : 'Ver más' }}
                </button>
            </div>
            <UtilsGallery
                :data="showMoreArtworks ? epData.data.artworks.data : epData.data.artworks.data.slice(0, 5)"
            />
        </section>
        <section id="bloque-de-artistas" v-if="epData.data.artists.data.length" class="collection__artists">
            <div class="artists">
                <div class="artists__left">
                    <h2>
                        Artistas de la
                        {{
                            epData.data.type === 'collection' || epData.data.type === 'special_project'
                                ? 'colección'
                                : 'exhibición'
                        }}
                        ‘‘{{ epData.data.title }}’’
                    </h2>
                    <button
                        @click="selectedArtistIndex = key"
                        v-for="(item, key) in epData.data.artists.data"
                        class="item group"
                    >
                        <h3
                            :class="selectedArtistIndex === key ? 'text-black' : 'group-hover:text-black'"
                            class="text-[#A0A0A0]"
                        >
                            {{ item.name }} {{ item.last_name }}
                        </h3>
                    </button>
                </div>

                <div class="artists__right">
                    <NuxtLink
                        :to="'/artistas/' + epData.data.artists.data[selectedArtistIndex].slug"
                        class="artists__right-image"
                    >
                        <img
                            :src="epData.data.artists.data[selectedArtistIndex].avatar"
                            :alt="epData.data.artists.data[selectedArtistIndex].name"
                            :onerror="`this.src=&quot;/no-image.jpg&quot;`"
                        />
                    </NuxtLink>
                    <div class="right__info">
                        <NuxtLink :to="'/artistas/' + epData.data.artists.data[selectedArtistIndex].slug">
                            <h3>
                                {{ epData.data.artists.data[selectedArtistIndex].name }}
                                {{ epData.data.artists.data[selectedArtistIndex].last_name }}
                            </h3>
                        </NuxtLink>
                        <NuxtLink
                            :to="'/artistas/' + epData.data.artists.data[selectedArtistIndex].slug"
                            class="icon"
                        >
                            <UnoIcon class="i-mdi:arrow-right" />
                        </NuxtLink>
                    </div>
                    <p>
                        <b
                            >{{
                                epData.data.type === 'collection' || epData.data.type === 'special_project'
                                    ? 'Colección'
                                    : 'Exhibición'
                            }}:</b
                        >
                        {{ epData.data.title }}
                    </p>
                </div>
            </div>
            <NuxtLink class="artists__seemore" :to="'/artistas?post_id=' + epData.data.id">
                Ver más artistas de esta
                {{
                    epData.data.type === 'collection' || epData.data.type === 'special_project'
                        ? 'colección'
                        : 'exhibición'
                }}
                <UnoIcon class="i-mdi:arrow-right" />
            </NuxtLink>
        </section>
        <section v-if="epData.data.latest_collections.length" class="collection__lasts">
            <h2>Lo más destacado</h2>
            <div class="lasts__grid">
                <CardBasic v-for="(item, key) in epData.data.latest_collections" :key="key" :data="item" />
            </div>
        </section>
    </main>
</template>

<style lang="postcss" scoped>
.collection {
    @apply font-Pathway;
    &__head {
        @apply xl:flex gap-10 sm:mt-5 mb-10 sm:container;
        & img {
            @apply mx-auto h-[350px] xl:w-[650px] xl:h-[550px] 2xl:w-[795px] 2xl:h-[625px] object-cover my-auto;
        }
        & .head__info {
            @apply px-[12px] sm:px-0 flex flex-col items-start justify-start pt-5 xl:pt-0;
            & .icon {
                @apply self-end text-2xl border pt-1.5 px-1 border-black hover:text-white hover:bg-black transition;
            }
            & h1 {
                @apply mt-2 lg:mt-0 tracking-[-0.02em] leading-[118%] font-bold text-[35px] lg:text-[65px];
            }
            & p:first-of-type {
                @apply mt-3 lg:mt-5 text-[#929292] font-medium font-Inter leading-[120%] text-[22px] lg:text-[35px] tracking-[-0.02em];
            }
            & p:last-of-type {
                @apply mt-5 lg:mt-12 text-base lg:text-lg tracking-[-0.02em] leading-[120%] font-Inter text-[#606060];
            }
            & a:last-of-type {
                @apply mt-7 lg:mt-10 px-14 py-2 w-full sm:w-fit text-center font-semibold border border-black hover:text-white hover:bg-black transition text-base lg:text-xl;
            }
        }
    }
    &__swiper {
        @apply container lg:pt-10;
        & .swiper__head {
            @apply flex justify-between items-start pb-5 gap-3;
            & h2 {
                @apply font-Pathway font-extrabold text-xl lg:text-2xl;
            }
            & button {
                @apply flex-none text-site-primary font-Inter font-semibold text-xl lg:text-2xl hover:underline;
            }
        }
    }
    &__artists {
        scroll-margin-top: var(--header-height-mobile);

        @screen md {
            scroll-margin-top: var(--header-height-md);
        }
        @screen lg {
            scroll-margin-top: var(--header-height);
        }

        @apply container my-10 lg:my-16 py-10 lg:py-20 border-y border-black lg:px-20;
        & .artists {
            @apply flex flex-col md:flex-row justify-between w-full gap-y-10;
            &__left {
                @apply flex-none w-full md:w-1/2 lg:w-3/5 flex flex-col gap-4;
                & h2 {
                    @apply font-Pathway font-extrabold text-xl lg:text-[26px] pb-4 lg:pb-8;
                }
                & .item {
                    @apply flex flex-col gap-0 transition w-fit;
                    & p {
                        @apply font-Pathway font-medium text-[15px];
                    }
                    & h3 {
                        @apply font-Pathway font-extrabold text-[44px];
                    }
                }
            }
            &__right {
                @apply flex-none w-full md:w-1/2 lg:w-2/5 flex flex-col;
                &-image {
                    @apply aspect-1 block;
                }
                & img {
                    @apply w-full h-full object-cover;
                }
                & .right__info {
                    @apply flex items-center gap-2 justify-between pt-4;
                    & h3 {
                        @apply font-Pathway font-extrabold text-xl lg:text-[23px];
                    }
                    & .icon {
                        @apply border border-black hover:bg-black hover:text-white transition;
                        & div {
                            @apply mt-1.5 px-5 text-2xl;
                        }
                    }
                }
                & p {
                    @apply mt-2 text-[#7C7C7C] font-Inter text-base lg:text-lg;
                }
            }
        }
        & .artists__seemore {
            @apply mt-10 flex items-center gap-4 font-Inter font-medium text-xl hover:bg-black hover:text-white transition border py-2.5 px-4 border-black w-fit;
            & div {
                @apply text-2xl;
            }
        }
    }
    &__lasts {
        @apply container mt-20 mb-20;
        & h2 {
            @apply font-Pathway font-extrabold text-[21px] lg:text-[26px] pb-3;
        }
        & .lasts__grid {
            @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5 mt-5;
        }
    }
}
</style>
