<template>
    <div class="utils-gallery">
        <article v-for="(item, key) in data" :data-hash="key" class="utils-gallery__article">
            <NuxtLink v-if="item.slug" :to="'/series/' + item.slug">
                <picture>
                    <img
                        :src="item.cover || ''"
                        :alt="item.title"
                        :onerror="`this.src=&quot;/no-image.jpg&quot;`"
                /></picture>
                <h3>{{ item.title }}</h3>
                <p>{{ item.content || item.description }}</p>
            </NuxtLink>
            <div @click="() => openDialog(item.cover)" v-else>
                <picture>
                    <img
                        :src="item.cover || ''"
                        :alt="item.title"
                        :onerror="`this.src=&quot;/no-image.jpg&quot;`"
                /></picture>
                <h3>{{ item.title }}</h3>
                <p>{{ item.content || item.description }}</p>
            </div>
        </article>
    </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'
import { Models } from '~/types/models'

import { useModal } from '@/stores/modal'

const modalStore = useModal()

const openDialog = (item: any) => {
    modalStore.setModal({
        info: item,
        name: 'ModalFeaturedWork',
    })
}

const porps = defineProps({
    data: {
        type: Object as PropType<Array<Models.CollectionResource & Models.ArtworkResource>>,
    },
})
</script>

<style lang="postcss" scoped>
.utils-gallery {
    @apply flex flex-wrap justify-start items-start;
    &__article {
        @apply cursor-pointer w-1/2 pr-4 pb-4 sm:w-1/3 lg:w-1/4 xl:w-1/5;
        & a {
            @apply space-y-2;
        }
        & picture {
            @apply aspect-[4/3] block w-[150px] lg:w-[220px] shadow-xl;
            & img {
                @apply w-full h-full block object-cover;
            }
        }
        & h3 {
            @apply font-Pathway font-bold text-[16px] lg:text-[17px] pt-2;
        }
        & p {
            @apply font-Inter line-clamp-3 text-sm text-[#929292];
        }
    }
}
</style>
