<script setup lang="ts">
import { Models } from '~/types/models'

const props = defineProps({
    data: {
        type: Object as PropType<Models.CollectionResource>,
        required: true,
    },
})

const artistName = computed(
    () => props.data.artists.data.at(0)?.name + ' ' + props.data.artists.data.at(0)?.last_name
)
const path = computed(() => {
    return (props.data.type === 'exhibit'
        ? '/exhibiciones/'
        : props.data.type === 'collection'
        ? '/series/'
        : '/proyectos-especiales/') + props.data.slug
})
</script>

<template>
    <NuxtLink class="basic" :to="path">
        <picture>
            <img :src="data.cover" :alt="data.title" :onerror="`this.src=&quot;/no-image.jpg&quot;`"
        /></picture>
        <h3 v-if="data.artists.data.at(0)?.name">{{ artistName }}</h3>
        <h3 v-else>{{ data.title }}</h3>
        <p><b>Colección:</b> {{ data.title }}</p>
        <p></p>
    </NuxtLink>
</template>

<style lang="postcss" scoped>
.basic {
    @apply space-y-2 max-w-[177px] lg:max-w-[310px];
    & picture {
        @apply block w-[177px] h-[206px] lg:w-[310px] lg:h-[260px];
        img {
            @apply w-full h-full object-cover;
        }
    }
    & h3 {
        @apply font-Pathway font-bold text-[16px] lg:text-[23px] pt-2;
    }
    & p {
        @apply font-Inter font-medium text-[13px] lg:text-lg text-[#7C7C7C];
    }
}
</style>
